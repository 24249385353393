/* Custom Font File Imports */
/* ----------- */
@font-face {
  font-family: "GTWalsheim";
  src: local("GT"), url("./fonts/GTWalsheimPro-Light.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "GTWalsheim";
  src: local("GT"), url("./fonts/GTWalsheimPro-Regular.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "GTWalsheim";
  src: local("GT"), url("./fonts/GTWalsheimPro-Medium.woff2") format("woff2");
  font-weight: 600;
}

/* CSS Globals */
/* ----- */
* {
  font-family: "GTWalsheim", sans-serif;
}

body {
  margin: 0;
  font-family: "GTWalsheim", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
